<template>
	<header class="header" :class="{fixed: fixed, notTop: notTop}">
		<div class="container2">
			<div class="header__left">
				<router-link to="/" class="header--logo">
					<span class="icon-logo"><span class="path1"></span><span class="path2"></span></span>
				</router-link>
			</div>
			<div class="headerMenu" :class="[{hide: active}, topMenuClass]">
				<router-link v-for="link in links" :to="link.link">{{link.name}}</router-link>
			</div>
			<div class="header__right">
				<a href="tel:88432392119" class="header--phone">8 (843) 239-21-19</a>
				<a class="openMenu" :class="{active: active}" @click="$emit('openMenuFunc')"><span class="burger"><span></span><span></span><span></span></span>Меню</a>
			</div>
		</div>
	</header>
</template>

<style lang="sass">
	@import '@/assets/sass/Header'
</style>

<script>
	import menuLinks from "@/assets/json/menuLinks.json";

	export default {
		props: ['active', 'topMenuClass'],
		data: () => ({
			links: menuLinks,
			fixed: false,
			lastScrollPos: 0,
			notTop: false
		}),
		methods: {
			headerFix: function(){
				var scrollTop = window.scrollY;
				
				if(scrollTop > this.lastScrollPos){
					this.fixed = false;
				} else{
					this.fixed = true;
				}

				if(scrollTop > 20){
					this.notTop = true;
				} else{
					this.notTop = false;
				}

				this.lastScrollPos = scrollTop;

				var burger = this.$el.getElementsByClassName("openMenu")[0];

				var topFixedClass = 20;

				if(this.$route.meta.firstBlockBanner){
					topFixedClass = 660;
				}

				if(window.innerWidth > 1100){
					if(scrollTop > topFixedClass){
						burger.classList.add("fixed");
					} else{
						burger.classList.remove("fixed");
					}
				} else{
					burger.classList.remove("fixed");
				}
			}
		},
		created: function () {
			window.addEventListener('scroll', this.headerFix);
			window.addEventListener('resize', this.headerFix);
		},
		destroyed: function () {
			window.removeEventListener('scroll', this.headerFix);
			window.removeEventListener('resize', this.headerFix);
		}
	}
</script>